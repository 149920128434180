.main {
    /* max-width: 980px; */
    position: relative;
    z-index: 1;
}

.block {
    display: flex;
    flex-direction: row;
}

.block-reverse {
    display: flex;
    flex-direction: row-reverse;
}

.image {
    max-width: 100%;
}

.block-image {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.block-content-wrapper {
    width: 50%;
    margin-top: 15px;
}

.block-content {
    width: 100%;
    display: flex;
    text-align: left;
    margin-top: 17px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    min-width: 615px;
}

/* ----------------- Mobile ---------------*/

.title-mobile {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 37px;

    /* BeeHire Black */

    color: #000000;
    padding-left: 20px;
    padding-right: 20px;
}

.block-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.block-content-wrapper-mobile {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}

.block-image-mobile {
    width: 90%;
    display: flex;
    align-items: center;
}

.block-content-mobile {
    text-align: left;
    padding-top: 18px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.block-mobile-reverse {
    flex-direction: column-reverse;
}