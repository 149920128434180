.footer {
    min-height: 620px;
    background: #000000;
    color: #F2F2F2;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: left;
    width: 1440px;
}

.footer-wrapper {
    margin: 0 auto;
    padding: 50px 120px;
}

.linkin-page {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
}

.linkin-page > a {
    margin-left: 15px;
    text-decoration: none;
    color: #F2F2F2;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
}

.footer-arrow {
    font-size: 20px;
    margin-left: 5px;
}

.roadmap-wrapper {
    display: flex;
    flex-direction: row;
    margin-top: 100px;
}

.column {
    min-width: 260px;
}

.footer-menu {
    padding-left: 0;
    text-align: left;
}

.footer-menu li {
    margin-bottom: 50px;
    list-style-type: none;
}

.footer-menu li > a {
    text-decoration: none;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #F2F2F2;
}

.location-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #F2F2F2;
}

.location-contact {
    display: flex;
    margin-top: 50px;
    color: #F2F2F2;
}

.location-map {
    display: flex;
    margin-top: 20px;
}

.location-contact-block {
    min-width: 150px;
    max-width: 220px;
}

.location-email {
    color: #F2F2F2;
    text-decoration: none;
}

.location-address {
    color: #2D9CDB;
}

.footer-license {
    padding-top: 98px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #fff;
}

.footer-award {
    width: 260px;
}

/*---------------------------------------Mobile-------------------------------------*/
.footer-mobile {
    background: #000000;
    padding: 30px 22px;
}

.column-mobile {
    width: 50%;
}

.roadmap-wrapper-mobile {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 65px;
}

.footer-divide {
    border-top: 1px solid #FFFFFF;
}

.location-contact-mobile {
    display: flex;
    flex-direction: column;
    margin-top: 35px;
    color: #F2F2F2;
}

.location-wrapper-mobile {
    text-align: left;
    margin-top: 30px;
}

.location-contact-block-mobile {
    margin-top: 10px;
    margin-bottom: 10px;
}

.footer-license-mobile {
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #fff;
    gap: 20px;
}

.footer-award-mobile {
    margin: 0 auto;
    margin-bottom: 40px;
}