* {
    margin: 0;
    box-sizing: border-box;
}

/* ---------------- Navbar and burger menu ------------------ */
.nav {
    width: 100px;
    height: 5em;
    display: flex;
    flex-direction: row;
    padding: 1em;
    z-index: 1;
    position: absolute;
    padding-top: 45px;
    right: 0;
}

.burger-menu {
    height: 12px;
    width: 22px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer;
}

.burger-bar {
    width: 22px;
    height: 2px;
    background-color: #4B4B4B;
}

.menu {
    width: 100%;
    height: 100vh;
    background-color: #000000;;
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: -1;
}

.menu-text {
    padding-left: 5px;
    margin-top: -6px;
    color: #4B4B4B;
}

.menu-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.menu-logo {
    width: 147px;
    height: 35px;
    margin-top: 36px;
    margin-left: 33px;
}

.menu-cross {
    color: #fff;
    width: 24px;
    font-size: 24px;
    margin-top: 42px;
    margin-right: 30px;
}

.menu-link-wrapper {
    border-left: 3px solid #FFFFFF;
    width: 165px;
    height: 255px;
    text-align: left;
    display: flex;
    flex-direction: column;
    margin-left: 48px;
    margin-top: 40px;;
}

.menu-link {
    text-decoration: none;
    color: #F2F2F2;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 50px;
    margin-left: 30px;
}

.menu-footer {
    margin-top: 45px;
    margin-left: 48px;
    margin-right: 48px;
}

.menu-license-mobile {
    color: #ffffff;
    line-height: 24px;
    padding-bottom: 20px;
}

.menu-footer-wrapper {
    margin-top: 36px;
    padding-left: 0;
    text-align: left;
}

.menu-footer-wrapper li {
    margin-bottom: 40px;
    list-style-type: none;
}

.menu-footer-wrapper li > a {
    text-decoration: none;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #F2F2F2;
}

/* ------------- sliding menu ------------------ */
.hidden {
    display: none;
    z-index: -1;
}

.visible {
    display: block;
    z-index: 1000;
    overflow: auto;
}
