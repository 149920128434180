.App {
  text-align: center;
  background-color: #F2F2F2;;
  color: #000000;
  width: 1440px;
  margin: 0 auto;
  white-space: pre-line;
  user-select: none;
  overflow-x: auto;
  scroll-behavior: smooth;
  overflow-x: hidden;
  overflow-y: auto;
}

.noscroll{
  overflow: hidden;
}

.content
{
  text-align: left;
  width: 1440px;
}

.content-wrapper {
  width: 100%;
  margin: 0 auto;
  padding-left: 210px;
  padding-right: 210px;
}

.title {
    font-family: 'Roboto Slab', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 53px;
    color: #000000;
    position: relative;
    z-index: 1;
}

.button-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 20px;
}

.content-list {
  margin-top: 26px;
  padding-left: 0px;
}

.content-list-li {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #BFBFBF;
}

.content-list-image {
  width: 24px;
  height: 24px;
}

.content-list-text {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}

.header-background {
  position: absolute;
  top: 0;
  z-index: 0;
}

/* -------------------------------------------------- Home Page -----------------------------------------------*/
.homepage-content-1 {
  margin-top: 100px;
  margin-bottom: 170px;
  position: relative;
  z-index: 1;
}

.homepage-content {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4B4B4B;
}

.homepage-subtitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  color: #000;
  text-decoration: none;
}

.homepage-subcontent {
  font-family: 'Roboto';
  font-style: normal;
  font-size: 16px;
  color: #000;
  text-decoration: none;
}

.home-content-background {
  position: absolute;
  top: 0;
  z-index: 0;
}

.homepage-section-1-imageBlock {
  position: absolute;
  top: -76px;
  left: 60%;
}

.homepage-content-1-footer {
  padding-top: 20px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
}

.homepage-content-link-footer {
  margin-top: 50px;
}

.homepage-content-link-footer a {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  color: #2F80ED;
  text-decoration: none;
}

.homepage-content-2 {
  background-color: #fff;
  width: 100%;
  padding-top: 80px;
  padding-bottom: 52px;
  padding-right: 0px;
}

.homepage-section-2-imageBlock {
  position: absolute;
  z-index: 0;
  padding-right: 165px;
  top: 30px;
}

.homepage-block-content-2 {
  width: 455px;
  position: relative;
  margin-left: 58px;
}

.homepage-content-2-footer a{
  margin-right: 10px;
}

.homepage-content-3-footer {
  text-align: center;
  max-width: 1000px;
}

.homepage-content-3-wrapper {
  padding-right: 0px;
}

.homepage-content-3 {
  padding-top: 80px;
  margin-bottom: 100px;
}

.homepage-content-3-slider {
  margin-top: 100px;
}

.homepage-content-4-title {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 53px;
  /* identical to box height */

  text-align: center;

  color: #FFFFFF;
}

.homepage-content-4 {
  background-color: #1B1B1B;
  width: 100%;
  min-height: 600px;
  font-family: 'Roboto';
  font-style: normal;
  color: #fff;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}

.homepage-content-4-background {
  position: absolute;
  z-index: 0;
}

.homepage-content-4-wrapper {
  position: relative;
  z-index: 1;
  max-width: 1040px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.homepage-content-4-title {
  padding-top: 221px;
  font-weight: 700;
  font-size: 40px;
}

.homepage-content-4-content {
  padding-top: 40px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.homepage-content-4-button {
  width: 180px;
  height: 44px;
  background: #FFCA05;
  border-radius: 30px;
  text-align: center;
  padding: 10px 14px;
  margin-top: 40px;
}

.homepage-content-4-button a {
  font-size: 16px;
}

.homepage-content-5 {
  width: 100%;
  min-height: 600px;
  font-family: 'Roboto';
  font-style: normal;
  color: #fff;
  padding-right: 0;
}

.homepage-content-5-wrapper {
  padding-right: 0px;
  background-color: #fff;
  padding-top: 67px;
}

.homepage-content-5-subtitle {
  margin-top: 30px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #4B4B4B;
}

.homepage-content-5-list {
  display: flex;
  flex-direction: row;
  gap: 72px;
}

.homepage-content-5-item {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 220px;
  margin-top: 67px;
}

.item-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #000000;
  margin-top: 12px;
}

.item-content {
  margin-top: 12px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #4B4B4B;
}

/* -------------------------------------------------- Jobs -------------------------------------------------*/
.jobs-content-1 {
  padding-top: 55px;
  position: relative;
  z-index: 0;
}

.jobs-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.jobs-header-background {
  position: absolute;
  z-index: 0;
  background: #FFF100;
  width: 1440px;
  height: 339px;
  top: 0px;
}

.job-header-bg {
  width: 1440px;
  height: 339px;
}

.jobs-header-logo {
  position: absolute;
  z-index: 0;
  left: 60%;
  top: 101px;
}

.jobs-title {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #4B4B4B;
}

.jobs-content {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4B4B4B;
  margin-top: 17px;
}

.jobs-hashtags-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow: auto;
  margin-top: 40px;
  gap: 10px;
  padding-left: 0;
}

.hashtag {
  width: auto;
  min-width: 96px;
  height: 44px;
  padding: 14px 14px;
  background-color: #FFFFFF;
  border-radius: 40px;
  margin-right: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  list-style-type: none;
  text-align: center;
}

.jobs-wrapper {
  padding-left: 118px;
  padding-right: 118px;
  margin-top: 68px;
  background-color: #fff;
  padding-top: 44px;
  padding-bottom: 107px;
}

.jobs-subtitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #4B4B4B;
}

.jobs-block-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}

.jobs-block {
  width: 361px;
  height: 260px;
  padding: 27px 23px;
  position: relative;
  margin: 20px;
  text-decoration: none;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 8px;
}

.jobs-block-divide {
  position: absolute;
  width: 3px;
  height: 223px;
  left: 1px;
}

.divide-cyan {
  background: #0FD0F5;
}

.divide-yellow {
  background: #FFF100;
}

.divide-pink {
  background: #F43786;
}

.jobs-block-viewmore {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #4B4B4B;
}

.last {
  background-color: #ECECEC;
  border-radius: 5px;
  padding-top: 110px;
}

.jobs-footer-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-top: 40px;
}

.jobs-footer {
  width: 100%;
  height: 131px;
  margin-top: 41px;
  border: 2px dashed #BFBFBF;
  border-radius: 8px;
}

.jobs-footer-notify {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 14px;
  gap: 10px;

  width: 132px;
  height: 44px;
  background-color: #fff;
  border: 1px solid #4B4B4B;
  border-radius: 4px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  cursor: pointer;
}

.jobs-alert-button {
  width: 120px;
  height: 36px;
  border: 1px solid #000;
  border-radius: 20px;
  padding: 10px 14px;
  display: flex;
  flex-direction: row;
  user-select: none;
  cursor: pointer;
}

.jobs-alert-button-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  padding-left: 5px;
}

/* -------------------------------------------------- Talnets ----------------------------------------------*/
.talnet-header-background {
  position: absolute;
  z-index: 0;
  /* background: #FFF100; */
  width: 1440px;
  height: 475px;
  top: 0px;
}

.talnet-header-bg {
  width: 1440px;
  height: 475px;
}

.talnet-header-logo {
  position: absolute;
  z-index: 0;
  left: 54%;
  top: 90px;
}

.talnet-bubble {
  position: absolute;
  z-index: 0;
  padding-top: 110px;
  padding-left: 946px;
}

.talnet-content-1 {
  position: relative;
  z-index: 1;
  margin-top: 67px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #4B4B4B;
}

.talnet-content-1-subcontent
{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #4B4B4B;
  margin-top: 20px;
}

.talnet-content-2 {
  background-color: #1B1B1B;
  width: 100%;
  min-height: 540px;
  font-family: 'Roboto';
  font-style: normal;
  font-size: 16px;
  color: #fff;
  padding-top: 145px;
}

.talnet-content-3-wrapper {
  background-color: #fff;
  padding-top: 59px;
  padding-bottom: 171px;
  padding-left: 139px;
}

.talnet-content-3-logo {
  position: absolute;
  z-index: 0;
  padding-top: 260px;
}

.talnet-btn-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding-top: 40px;
  position: relative;
  z-index: 1;
}

.talnet-btn {
  padding-top: 20px;
  width: 209px;
}

.talnet-content-3 {
  width: 100%;
  height: 690px;
  position: relative;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: contain;
}

.talnet-content-3-title {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  padding-top: 39px;
  padding-left: 82px;
  color: #4B4B4B;
}

.talnet-content-3-content {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  padding-left: 82px;
  padding-top: 23px;
  color: #4B4B4B;
}

.talnet-content-3-cards-wrapper {
  display: flex;
  margin-left: 388px;
  margin-top: 54px;
  width: 850px;
  flex-wrap: wrap;
}

.talnet-content-3-card {
  gap: 30px;
  border-radius: 8px;
  padding: 20px;
  margin: 18px 10px;
  width: 400px;
  min-height: 240px;
  position: relative;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.talnet-content-3-card-title-wrapper {
  display: flex;
  height: 40px;
  margin-top: 15px;
}

.talnet-content-3-card-logo-checkbox {
  width: 21.04px;
  height: 24px;
}

.talnet-content-3-card-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #4B4B4B;
  padding-left: 20px;
}

.talnet-content-3-card-content {
  position: relative;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4B4B4B;
  width: 100%;
  padding-left: 40px;
}

.talnet-content-4 {
  margin-top: 88px;
}

.talnet-content-4-title {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  color: #4B4B4B;
}

.talnet-content-4-trends-wrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 41px;
}

.trend-block {
  width: 284px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  margin-bottom: 55.25px;
}

.trend-block-thumbnail {
  width: 284px;
  height: 159.75px;
}

.trend-block-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #4B4B4B;
}

.trend-reporter {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #4B4B4B;
}

.talnet-content-link-footer {
  text-align: center;
  margin-bottom: 75px;
}

.talnet-content-link-footer a {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #2F80ED;
  text-decoration: none;
}
/* -------------------------------------------------- About Us ------------------------------------------------*/
.about-header-logo {
  position: absolute;
  z-index: 0;
  top: 55px;
  left: 820px;
}

.about-header-background {
  position: absolute;
  z-index: 0;
  width: 1440px;
  height: 410px;
  top: 0px;
}

.about-header-bg {
  width: 1440px;
  height: 410px;
}

.about-content-2-logo {
  position: absolute;
  z-index: 1;
  padding-top: 130px;
  padding-left: 792px;
}

.about-bubble {
  position: absolute;
  z-index: 0;
  padding-top: 110px;
  padding-left: 946px;
}

.about-bubble-image {
  position: relative;
  top: -169px;
}

.about-us-content-1 {
  margin-top: 100px;
  margin-bottom: 48px;
  position: relative;
  z-index: 1;
}

.about-us-content-1-content {
  padding-left: 70px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.about-us-content-2-wrapper {
  background-color: #fff;
  padding-top: 180px;
  padding-bottom: 13px;
}

.about-us-content-2 {
  background-color: #1B1B1B;
  width: 100%;
  min-height: 502px;
  font-family: 'Roboto';
  font-style: normal;
  color: #fff;
  padding-top: 92px;
}

.about-us-content-2-footer {
  padding-top: 45px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 72px;
}

.about-us-content-2-link {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #2F80ED;
  cursor: pointer;
  text-decoration: none;
}

.about-us-content-3 {
  background-color: #1B1B1B;
  width: 100%;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #F2F2F2;
  padding-top: 92px;
  height: 419px;
  padding-bottom: 100px;
}

.about-us-content-4-wrapper {
  background-color: #fff;
  background-image: url('../images/about-content-4-bg.png');
  padding-top: 92px;
}

.about-us-content-4-title {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  color: #4B4B4B;
}

.team-list-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 53px;
}

.team-list-block {
  width: 182px;
  height: 270px;
}

.team-list-photo {
  width: 158px;
  height: 158px;
  border-radius: 50%;
}

.team-list-photo-default {
  /* background-color: #D9D9D9; */
  width: 158px;
  height: 158px;
  border-radius: 50%;
  margin-left: 12px;
  margin-right: 12px;
}

.team-list-name {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  color: #4B4B4B;
  padding-top: 20px;
}

.team-list-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #4B4B4B;
  padding-top: 9px;
}

.team-list-area {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #000000;
  opacity: 0.5;
  padding-top: 9px;
}

.team-list-button-wrapper {
  margin-top: 58px;
  padding-bottom: 85px;
  text-align: center;
}

.team-list-button {
  border: 1px solid #4B4B4B;
  border-radius: 4px;
  padding: 10px 14px;
  gap: 10px;
  background-color: #fff;
  width: 288px;
  height: 44px;
  border: 1px solid #BFBFBF;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  text-decoration: none;
  color: #4B4B4B;
}

/* -------------------------------------------------- Join Our Team ------------------------------------------------*/

.join-header-logo {
  position: absolute;
  z-index: 0;
  top: 65px;
  left: 820px;
}

.join-header-background {
  position: absolute;
  z-index: 0;
  width: 1440px;
  height: 469px;
  top: 0px;
}

.join-header-bg {
  width: 1440px;
  height: 469px;
}

.join-our-team-content-1 {
  margin-top: 100px;
  position: relative;
  z-index: 1;
}

.join-our-team-btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.join-our-team-content-2-wrapper {
  padding-top: 170px;
  padding-bottom: 127px;
  padding-left: 134px;
  padding-right: 120px;
  background-color: #FFFFFF;
}

.join-content-2-background {
  position: absolute;
  z-index: 0;
  padding-top: 78px;
}

.join-out-team-content-2 {
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 1;
}

.join-our-team-content-2-background {
  width: 570px;
  height: 690px;
  background-repeat: no-repeat;
  background-size: contain;
}

.join-our-team-content-2-title {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  height: 114px;
  color: #4B4B4B;
  padding-left: 102px;
  position: relative;
  z-index: 1;
}

.join-our-team-photo {
  width: 386px;
  height: 217px;
}

.photo-album-wrapper {
  display: flex;
  flex-direction: column;
  width: 590px;
  gap: 10px;
}

.join-our-team-photo-1 {
  margin-left: 102px;
}

.join-our-team-photo-3 {
  margin-left: 102px;
}

.photo-album-footer {
  margin-top: 53px;
}

.photo-album-footer a {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #2F80ED;
  text-decoration: none;
}

.join-our-team-content-2-content-wrapper {
  width: 484px;
  margin-left: 80px;
}

.join-our-team-content-2-content {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #4B4B4B;
}

.join-our-team-li {
  width: 100%;
  padding-top: 23px;
  border-bottom: 1px solid #BFBFBF;
  margin-top: 0px;
}

.join-our-team-content-3 {
  margin-top: 100px;
  margin-bottom: 100px;
}

.join-our-team-content-3-wrapper {
  padding-right: 0px;
}

.join-slider {
  margin-top: 40px;
}

.join-our-team-content-4 {
  text-align: center;
  padding-top: 100px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.join-our-team-content-4-wrapper
{
  height: 310px;
}

.join-our-team-content-4-background-img {
  height: 310px;
  width: 1440px;
}

.join-content-4-background {
  position: absolute;
  z-index: 0;
}

.join-content-4-title {
  position: relative;
  z-index: 1;
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  text-align: center;
  color: #FFFFFF;
}

.join-btn {
  position: relative;
  z-index: 1;
  gap: 10px;
  width: 149px;
  height: 44px;
  background: #FFCA05;
  border-radius: 30px;
  margin-top: 20px;
}


/* -------------------------------------------------- Locaitons ------------------------------------------------*/
.location-header-logo {
  position: absolute;
  z-index: 0;
  left: 820px;
  padding-top: 65px;
}

.location-header-background {
  position: absolute;
  z-index: 0;
  width: 1440px;
  height: 387px;
  top: 0px;
}

.location-header-bg {
  width: 1440px;
  height: 387px;
}

.location-content-4-banner {
  position: absolute;
  z-index: 0;
}

.location-banner-bg-2 {
  position: absolute;
  z-index: 2;
  left: 0;
}

.location-banner-bg-3 {
  position: absolute;
  z-index: 3;
  right:0;
  top: -130px;
}

.location-content-1 {
  margin-top: 100px;
  margin-bottom: 82px;
  position: relative;
  z-index: 1;
}

.location-content-2-wrapper {
  background-color: #fff;
}

.location-content-2 {
  padding-top: 100px;
  padding-bottom: 82px;
}

.location-content-2-email {
  color: #2D9CDB;
}

.location-content-2-photos {
  display: flex;
  flex-direction: row;
  margin-top: 39px;
}

.location-content-2-photo-wrapper {
  width: 50%;
  margin-right: 11px;
}

.location-content-2-photo {
  width: 178px;
  height: 100px;
}

.location-content-2-map {
  display: flex;
  flex-direction: column;
  margin-top: 110px;
  margin-left: 200px;
}

.location-content-2-address {
  color: #2D9CDB;
  padding-top: 19px;
  text-decoration: none;
}

.location-content-zero-top {
  padding-top: 0px;
}

.location-content-3 {
  padding-bottom: 82px;
}

.location-content-4 {
  margin-top: 100px;
  padding-bottom: 178px;
}

.location-btn {
  position: relative;
  z-index: 4;
}
/* -------------------------------------------------- Job Details ------------------------------------------------*/

.job-details-content-2 {
  background-color: #fff;
  padding-top: 175px;
  padding-bottom: 82px;
  display: flex;
  flex-direction: row;
}

.job-details-block-left {
  width: 70%;
}

.job-details-block-right {
  width: 30%;
}

.job-details-content-2-link {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #2F80ED;
  text-decoration: none;
}

.job-details-content-2-title {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;

  color: #4B4B4B;
  padding-top: 23px;
}

.job-details-content-2-block-left-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #4B4B4B;
  padding-top: 50px;
  padding-bottom: 25px;
}

.job-details-content-2-block-left-content {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #4B4B4B;
  margin-top: 25px;
}

.job-details-content-2-description-wrapper {
  display: flex;
  flex-direction: row;
}

.job-details-content-2-requirement {
  width: 30%;
}

.job-details-right-wrapper {
  width: 387px;
  height: 715px;
  background: rgba(242,242,242,0.5);
  border-radius: 8px;
  margin-top: 80px;
  padding: 48px 32px;
}

.job-details-block-right-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #4B4B4B;
}

.job-details-skillset-details {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #4B4B4B;
}

.job-details-divide {
  width: 100%;
  border: 1px solid #BFBFBF;
  margin-top: 25px;
  margin-bottom: 25px;
}

.job-details-block-right-subtitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #FBAD16;
  margin-top: 25px;
  margin-bottom: 10px;
}

.job-details-block-right-subdetails {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #4B4B4B;
}

.job-details-content-2-button-wrapper {
  margin-top: 60px;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.job-details-button {
  border: 1px solid #4B4B4B;
  border-radius: 30px;
  height: 44px;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 14px;
}

.job-details-apply-button {
  background-color: #000;
}

.job-details-button-link {
  text-decoration: none;
  color: #ffffff;
}

.job-details-copy-button {
  cursor: pointer;
}

.job-details-button-link {
  text-decoration: none;
  color: #ffffff;
}

/* -------------------------------------------------- Terms ------------------------------------------------*/
.terms-header-background {
  position: absolute;
  z-index: 0;
  background: #FFF100;
  width: 1440px;
  height: 220px;
  top: 0px;
}

.terms-title {
  position: relative;
  z-index: 1;
  margin-top: 65px;
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  color: #4B4B4B;
}

.terms-content {
  background-color: #fff;
  margin-top: 42px;
  padding-top: 49px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 92px;
}

.terms-header-bg {
  width: 1440px;
  height: 180px;
}

/* -------------------------------------------------- Mobile -----------------------------------------------*/
.App-mobile {
  width: 100%;
}

.content-mobile {
  text-align: left;
}

.content-wrapper-mobile {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.button-wrapper-mobile {
  width: 100%;
  max-width: 335px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 20px;
  position: relative;
  z-index: 1;
  padding-left: 20px;
}

.button-mobile {
  width: 140px;
}


/* -------------------------------------------------- Home Page Mobile-----------------------------------------------*/
.home-content-background-mobile {
  width: 100%;
  height: 779px;
}
.home-content-background-mobile > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home-content-content-1-logo-mobile {
  position: absolute;
  z-index: 0;
  top: 400px;
  right: 0px;
}

.homepage-content-2-mobile {
  padding-top: 430px;
  background-color: #ffffff;
}

.homepage-section-2-imageBlock-mobile {
  padding-top: 16px;
}

.homepage-content-2-footer-mobile {
  margin-bottom: 50px;
}

.homepage-content-3-mobile {
  padding-top: 40px;
  margin-bottom: 66px;
}

.homepage-content-3-slider-mobile {
  margin-top: 40px;
  margin-left: 10px;
  margin-right: 10px;
}

.home-content-4-background-mobile {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 470px;
}

.home-content-4-background-mobile > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.homepage-content-4-mobile {
  position: relative;
  z-index: 1;
  width: 320px;
  height: 470px;
  padding-top: 35px;
}

.homepage-content-4-title-mobile {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 53px;
  text-align: center;
  color: #FFFFFF;
}

.homepage-content-4-content-mobile {
  padding-top: 33px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
}

.homepage-content-4-button-mobile {
  padding-top: 33px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.homepage-content-1-wrapper {
  margin-top: 50px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */


  /* UI Black */

  color: #4B4B4B;
}

/* -------------------------------------------------- Jobs Page Mobile-----------------------------------------------*/

.jobs-header-background-mobile {
  background-color: #FFCA05;
  width: 100%;
  height: 333px;
}

.jobs-header-background-mobile > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hashtag-mobile {
  width: auto;
  height: 44px;
  padding: 14px 14px;
  background-color: #FFFFFF;
  border-radius: 40px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  list-style-type: none;
  text-align: center;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.breadcrumb {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #4B4B4B;
  padding-left: 20px;
  padding-bottom: 26px;
}

.jobs-content-1-mobile {
  padding-top: 36px;
}

.job-title-mobile {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 37px;
  color: #000000;
  padding-left: 20px;
}

.jobs-content-mobile {
  padding-left: 20px;
}

.jobs-hashtags-wrapper-mobile {
  margin-top: 19px;
  padding-left: 20px;
}

.jobs-wrapper-mobile {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 35px;
}

.jobs-alert-button-mobile {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 14px;
  gap: 10px;

  background: #FFFFFF;
  /* UI Disabled */

  border: 1px solid #BFBFBF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.last-mobile {
  background-color: #ECECEC;
  border-radius: 5px;
  height: 60px;
  display: flex;
  align-items: center;
}

.jobs-footer-mobile {
  margin-top: 20px;
}

.jobs-footer-wrapper-mobile {
  width: 258px;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding-top: 16px;
}

.jobs-block-mobile {
  margin: 0px;
  margin-top: 20px;
}


/* -------------------------------------------------- Talnet Page Mobile-----------------------------------------------*/

.talnet-header-background-mobile {
  position: absolute;
  z-index: 0;
  /* background: #FFF100; */
  width: 100%;
  height: 740px;
  top: 0px;
  text-align: center;
}


.talnet-header-background-mobile > img {
  width: 100%;
}

.talnet-header-bg-mobile {
  height: 738px;
}

.talnet-header-logo-mobile {
  position: absolute;
  left: 0;
  top: 460px;
}

.talnet-content-1-mobile {
  margin-top: 36px;
}

.talnet-breadcrumb {
  padding-left: 20px;
  margin-bottom: 26px;
}

.talnet-bubble-mobile {
  position: absolute;
  z-index: 2;
  padding-top: 665px;
  padding-left: 0;
  right: 0;
}

.talnet-content-2-mobile {
  background-color: #1B1B1B;
  width: 100%;
  min-height: 576px;
  font-family: 'Roboto';
  font-style: normal;
  color: #fff;
  padding-top: 430px;
  padding-bottom: 140px;
}

.talnet-btn-wrapper-mobile {
  padding-top: 30px;
  padding-left: 20px;
  position: relative;
  z-index: 3;
}

.talnet-content-3-mobile {
  height: 1830px;
  background-color: #fff;
}

.talnet-content-3-logo-mobile {
  position: absolute;
  z-index: 2;
  padding-top: 200px;
  padding-left: 20px;
}

.talnet-content-3-title-mobile {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 37px;
  color: #000000;
  padding-left: 20px;
  padding-top: 140px;
}

.talnet-content-3-content-mobile {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4B4B4B;
  padding-top: 320px;
  padding-left: 20px;
  padding-right: 20px;
}

.talnet-content-3-cards-wrapper-mobile {
  margin-left: 0;
  width: 100%;
}

.talnet-content-3-card-mobile {
  margin: 20px 20px;
}

.talnet-content-3-card-3-mobile {
  min-height: 280px;
}


/* -------------------------------------------------- About Us Mobile -----------------------------------------------*/
.about-header-background-mobile {
  height: 601px;
  width: 100%;
  text-align: center;
}

.about-header-bg-mobile {
  width: 100%;
  height: 601px;
}

.about-header-logo-mobile {
  left: 0;
  position: absolute;
  top: 300px;
}

.about-us-breadcrumb {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #4B4B4B;
  padding-left: 20px;
  padding-bottom: 26px;
}

.about-us-content-1-mobile {
  margin-top: 29px;
}

.about-us-content-1-content-mobile {
  padding-left: 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 29px;
}

.about-us-content-1-text-mobile {
  padding-left: 16px;
}

.about-us-content-2-wrapper-mobile {
  min-height: 1004px;
  padding-top: 350px;
}

.about-content-2-logo-mobile {
  padding-left: 0;
  padding-top: 710px;
  right: 0;
}

.about-us-content-2-footer-mobile {
  flex-direction: column;
  gap: 20px;
}

.about-bubble-mobile {
  padding-left: 0;
  padding-top: 420px;
  right: 0;
}

.about-us-content-3-mobile {
  min-height: 540px;
  padding-top: 80px;
}

.about-us-content-4-title-mobile {
  padding-left: 20px;
  padding-top: 50px;
}

.team-list-wrapper-mobile {
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 53px;
  gap: 64px;
  padding-left: 35px;
  overflow-x: auto;
  overflow-y: hidden;
}

/* -------------------------------------------------- Join Us Mobile -----------------------------------------------*/
.join-header-background-mobile {
  width: 100%;
  height: 738px;
}

.join-header-bg-mobile {
  width: 100%;
  height: 738px;
}

.join-header-background-mobile > img {
  width: 100%;
}

.join-our-team-content-2-title-mobile {
  padding-left: 0;
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 37px;
  height: 70px;
}

.join-our-team-content-1-mobile {
  margin-top: 20px;
}

.join-header-logo-mobile {
  left: 0;
  top: 418px;
}

.join-our-team-content-2-wrapper-mobile {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 390px;
  padding-bottom: 60px;
}

.photo-album-wrapper-mobile {
  flex-direction: column;
  width: 100%;
}

.photo-ablum-sub-wrapper {
  display: flex;
  flex-direction: row;
  gap: 9px;
}

.join-our-team-photo-1-mobile {
  margin-left: 0;
}

.join-our-team-photo-2-mobile {
  margin-left: 0;
}

.join-our-team-photo-3-mobile {
  margin-left: 0;
}

.join-out-team-content-2-mobile {
  flex-direction: column;
}

.join-our-team-content-2-content-wrapper-mobile {
  width: 100%;
  margin: 0;
  margin-top: 30px;
}

.join-our-team-content-2-background-mobile {
  height: auto;
  width: 100%;
}

.join-slider-mobile {
  margin-top: 28px;
}

.join-our-team-content-3-mobile {
  margin-top: 60px;
  margin-bottom: 60px;
}

.join-content-link-footer {
  text-align: center;
  padding-top: 40px;
}

.join-content-link-footer a {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2F80ED;
  text-decoration: none;
}

.join-content-4-background-mobile {
  width: 100%;
  height: 560px;
}

.join-content-4-background-mobile > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.join-our-team-content-4-wrapper-mobile {
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 250px;
}

/* -------------------------------------------------- Locations -----------------------------------------------*/

.location-header-background-mobile {
  width: 100%;
  height: 558px;
}

.location-header-bg-mobile {
  width: 100%;
  height: 558px;
}

.location-header-logo-mobile {
  left: 0;
  top: 240px;
  width: 100%;
  padding-top: 0;
}

.location-content-1-mobile {
  margin-top: 40px;
}

.location-content-2-mobile {
  padding-top: 320px;
}

.location-content-2-photo-mobile {
  width: 100%;
}

.location-content-2-photos-mobile {
  gap: 12px;
}

.location-content-2-photo-wrapper-mobile {
  margin-right: 0;
}

.location-content-2-map-mobile {
  margin: 20px auto;
}

.location-content-4-banner-mobile {
  width: 100%;
}

.location-banner-bg-1-mobile{
  width: 100%;
  height: 420px;
}

.location-banner-bg-2-mobile {
  width: 100%;
}

.location-banner-bg-3-mobile {
  top: 147px;
  right: 0;
}

.location-content-4-mobile {
  margin-top: 50px;
  padding-bottom: 236px;
}

.location-btn-mobile {
  width: 175px;
}

/* -------------------------------------------------- Job Details -----------------------------------------*/
.jobs-details-content-1-mobile
{
  padding-top: 90px;
  padding-bottom: 31px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #ffffff;
}

.job-details-right-wrapper-mobile {
  width: 100%;
  height: 690px;
  background: rgba(242,242,242,0.5);
  border-radius: 8px;
  margin-top: 80px;
  padding: 48px 32px;
}
.job-details-content-2-button-wrapper-mobile
{
  align-self: center;
}
.job-details-content-2-title-mobile
{
  font-size: 28px;
}

/* -------------------------------------------------- Terms -----------------------------------------------*/
.terms-header-background-mobile {
  width: 100%;
  height: 200px;
}

.terms-content-wrapper-mobile {
  position: relative;
  z-index: 1;
  padding-top: 20px;
}

.terms-title-mobile {
  position: relative;
  z-index: 1;
  padding-left: 20px;
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 37px;
  color: #4B4B4B;
}

.terms-content-mobile {
  padding-left: 20px;
  padding-right: 20px;
}

.terms-header-bg-mobile {
  width: 100%;
}

/* -------------------------------------------------- Cannot Overwrite -----------------------------------------------*/

.tag-selected {
  background-color: #2F80ED;
  color: #fff;
}

.align-center {
  align-items: center;
}

#overlay {
  display: none;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  overscroll-behavior: contain;
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: 1;
}

#popup {
  display: none;
}

.popup {
  position: absolute;
  background-color: white;
  z-index: 2;
  top: 5%;
  left: 33%;
  max-height: none;
}

.popup-mobile {
  top: 2%;
  left: 0%;
  max-height: none;
}