.button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* width: 140px; */
    height: 44px;
    padding: 10px 14px;
    gap: 10px;
    background: #4B4B4B;
    border-radius: 24px;
}

.button-link {
    text-decoration: none;
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.primary {
    background: #FFCA05;
    color: #000000;
}

.default {
    background: #000000;
    color: #FFFFFF;
}

.secondary {
    background-color: #FFFFFF;
    color: #000000;
}