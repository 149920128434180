.form-content-wrapper {
    display: flex;
    flex-direction: column;
}

.form-content {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #28282B;
}