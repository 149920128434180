.header {
    background-color: #F2F2F2;;
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 5vh;
    min-width: 1200px;
}

.header-link {
    color: #4B4B4B;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-decoration: none;
    margin-left: 40px;
    position: relative;
    z-index: 1;
}

.header-logo {
    width: 147px;
    height: 35px;
    margin-left: 120px;
    margin-top: 20px;
    position: relative;
    z-index: 1;
}

.routes {
    margin-left: 56px;
    margin-top : 35px;
}

.header-selected {
    border-bottom: solid;
    padding-bottom: 8px;
}

/*---------------------------------------Mobile-------------------------------------*/

.header-mobile {
    background-color: #F2F2F2;;
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 5vh;
}

.header-logo-mobile {
    width: 147px;
    height: 35px;
    margin-top: 20px;
    margin-left: 20px;
    position: relative;
    z-index: 1;
}