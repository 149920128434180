.slider {
    max-width: 990px;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
}

.slider-block {
    max-width: 387px;
    min-height: 240px;
    font-family: 'Roboto';
    font-style: normal;
    background-color: #fff;
    border-radius: 8px;
    padding: 25px;
}

.slider-block-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #4B4B4B;
}

.slider-block-city  {
    margin-top: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #FBAD16;

}

.slider-block-exp {
    margin-top: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #4B4B4B;
}

.slider-block-tags-wrapper {
    height: 105px;
}

.slider-block-tags {
    display: flex;
    margin-top: 10px;
    flex-wrap: wrap;
    gap: 10px;
}

.slider-tag {
    margin-right: 20px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #4B4B4B;
    padding: 2px 10px;
    background: #F2F2F2;
    border-radius: 40px
}

.slider-block-head {
    height: 170px;
}

.slider-block-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    /* position: absolute; */
    bottom: 20px;
}

.slider-block-date {
    width: 50%;
    font-size: 14px;
    line-height: 16px;
    color: #4B4B4B;
}

.slider-block-salary {
    width: 50%;
    font-size: 24px;
    line-height: 16px;
    color: #4B4B4B;
    text-align: right;
}

.slider-block-mobile {
    margin-left: 20px;
    margin-right: 20px;
}

.slider-long-name {
    height: 80px;
}