.lightbox-background {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 800;
    background-color: #000;
    opacity: 0.8;
    top: 0;
    left: 0;
    animation: fadeIn 0.5s;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 0.8; }
}

@keyframes fadeFullIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.show {
    display: block;
}

.hide {
    display: none;
}

.lightbox-wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    z-index: 20;
    justify-content: center;
    top: 7%;
    left: 0;
}

.lightbox {
    animation: fadeFullIn 0.5s;
    max-width: 630px;
    max-height: 800px;
    z-index: 1000;
    min-height: 460px;
    width: 100%;
    background-color: #FFFFFF;
    border: 1px solid #fff;
    border-radius: 20px;
    padding: 47px 60px 51px 50px;
    position: relative;
}

.lightbox-title {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #4B4B4B;
    width: 81%;
}

.lightbox-cross {
    color: #000;
    font-size: 30px;
    position: absolute;
    right: 30px;
    top: 35px;
}

.lightbox-label {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #28282B;

    margin-top: 33px;
    display: flex;
    flex-direction: row;
}

.mandatory {
    color: #E10019;
}

.optional {
    color: #BFBFBF;
    margin-left: 5px;
}

.lightbox-input-wrapper {
    margin-top: 5px;
}

.lightbox-input {
    width: 100%;
    height: 54px;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    padding: 10px;
}

.lightbox-input-area {
    width: 100%;
    height: 100px;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    padding: 10px;
    resize: none;
}

.lightbox-file-upload {
    width: 100%;
    height: 139px;
    background-color: #F2F2F2;
    border-radius: 4px;
    border: 1px dashed #BFBFBF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.lightbox-file-upload-button {
    border-radius: 20px;
    border: 1px solid #4B4B4B;
    width: 108px;
    height: 39px;
    padding: 10px 14px;
    margin-top: 16px;
}

.lightbox-checkbox-wrapper {
    height: 50px;
}

.lightbox-checkbox-label {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 10px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #28282B;

    margin-top: 33px;
}

.lightbox-alert-submit {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px 14px;
    gap: 10px;

    width: 79px;
    height: 44px;

    /* UI Black */

    background: #4B4B4B;
    border-radius: 4px;
    border: 1px solid #4B4B4B;

    color: #fff;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

    margin-top: 30px;
}

.lightbox-error {
    color: #E10019;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin-left: 10px;
}

.lightbox-error-below {
    margin-left: 0;
    margin-top: 5px;
}

.lightbox-viewmore {
    animation: fadeFullIn 0.5s;
    max-width: 630px;
    max-height: 530px;
    z-index: 1000;
    min-height: 530px;
    width: 100%;
    background-color: #FFFFFF;
    border: 1px solid #fff;
    border-radius: 20px;
    padding: 47px 60px 51px 50px;
    position: relative;
}

.lightbox-success {
    animation: fadeFullIn 0.5s;
    max-width: 630px;
    max-height: 400px;
    z-index: 1000;
    min-height: 400px;
    width: 100%;
    background-color: #FFFFFF;
    border: 1px solid #fff;
    border-radius: 20px;
    padding: 120.5px 60px 51px 50px;
    position: relative;
    text-align: center;
}

.lightbox-success-message {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #4B4B4B;
    margin-top: 33px;
}

.lightbox-success-btn {
    width: 104px;
    height: 44px;
    padding: 10px 14px;
    gap: 10px;
    background: #4B4B4B;
    border-radius: 4px;
    color: #fff;
    margin-top: 33px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.lightbox-wrapper-mobile {
    top: 0.5%;
    position: absolute;
    overflow: auto;
    height: auto;
}

.lightbox-title-mobile {
    font-size: 16px;
}